

#app-container {
  height: 3000px;
}

.documents {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 섹션 내 요소를 수직으로 가운데 정렬합니다. */
  text-align: center;
  /* 내용을 가운데 정렬합니다. */
  height: 440px;
  /* 화면 높이의 100%만큼의 높이를 가짐 */
  background-color: whitesmoke;
  margin: 0;
  justify-content: center;
  align-items: center;

}

.content {
  display: flex;
  align-items: flex-start;
  /* 요소를 위쪽으로 정렬합니다. */
  
  place-items: center;
  height: max-content;
  margin-bottom: 30px;
}


.cardone {
  width: 90%;
  border: none;
  height: 250px;
}

.title {
  text-align: center;
  /* 제목을 가운데 정렬합니다. */
}

.titleone,
.titletwo {
  margin-bottom: 10px;
  /* 제목 사이의 간격을 조정합니다. */
}

.mainone {
  max-width: 70%;
  margin: 0;
  max-height: 80%;
}

#buttonone{
  width: 40%;
  margin-top: 10px;
  
}

.headerbar {
  margin: 0;
}

.textone {
  width: 100%;
  height: 100%;
  margin-right: 20px;
  text-align: center;
  /* 텍스트를 가운데 정렬합니다. */
}

@media screen and (max-aspect-ratio: 1/1) {

  /* 안드로이드 화면 모드인 경우 */
  .documents {
    flex-direction: column;
  }
  .imagefirst{
    display: none;
    /* 이미지를 숨깁니다. */
    App{
      margin: 0;
      
      
    }
   
  }
}

.imagewrap {
  /* 이미지와 카드 사이의 간격을 조정합니다. */
  display: flex;

}

.imagefirst {
  width: 300px;
  height: 300px;
  max-width: 100%;
}

hr.full-width {
  width: 100%;
  border: 1px solid black;
  /* 가로 줄의 스타일을 지정합니다. */
  margin: 0;
  /* 여백 제거 */
  padding: 0;
  /* 내부 여백 제거 */
}



/* 두번째 라인 */


  
@media screen and (max-aspect-ratio: 1/1) {

  /* 안드로이드 화면 모드인 경우 */
  .twoimagefirst{
    display: none;
    /* 이미지를 숨깁니다. */
  }


  .contenttwo{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .twomainone{
    margin: 0;
  }
 
}


.documentstwo {
  border-top: 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 섹션 내 요소를 수직으로 가운데 정렬합니다. */
  text-align: center;
  /* 내용을 가운데 정렬합니다. */
  height: 300px;
  /* 화면 높이의 100%만큼의 높이를 가짐 */
  background-color: whitesmoke;
  margin: 0;
  padding-bottom: 50px;
  
  

}

.contenttwo {
  display: flex;
  align-items: flex-start;
  /* 요소를 위쪽으로 정렬합니다. */
  height: 110px;
}

.cardone {
  width: 500px;
  border: none;
  height: 250px;
}


.twomainone {
  max-width: 70%;
  margin: 0 auto;
  /* margin-right: 50px; */
  max-height: 100%;
}

.twomaintwo {
  max-width: 70%;
  margin: 0 auto;
  /* margin-right: 50px; */
  max-height: 100%;
}

.twomainthree {
  max-width: 70%;
  margin: 0 auto;
  /* margin-right: 50px; */
  max-height: 100%;
}

.headerbar {
  margin: 0;
}

.textone {
  width: 100%;
  height: 100%;
  margin-right: 20px;
  text-align: center;
  /* 텍스트를 가운데 정렬합니다. */
  margin-bottom: 10px;
}



.twoimagewrap {
  /* 이미지와 카드 사이의 간격을 조정합니다. */
  display: flex;
  margin-right: 100px;
}

.twoimagefirst {
  width: 100px;
  height: 110px;
  max-width: 100%;
}

hr.full-width {
  width: 100%;
  border: 1px solid black;
  /* 가로 줄의 스타일을 지정합니다. */
  margin: 0;
  /* 여백 제거 */
  padding: 0;
  /* 내부 여백 제거 */
}


@media screen and (max-aspect-ratio: 1/1) {

  /* 안드로이드 화면 모드인 경우 */
 .documentstwo{

  
 }

  .contenttwo {
   margin: 0;
   padding: 0;
    height: 800px;
    padding-bottom: 100px;

    display: flex;
    
    align-items: center;
    /* 섹션 내 요소를 수직으로 가운데 정렬합니다. */
    text-align: center;
    /* 내용을 가운데 정렬합니다. */
    
    

  }
  .twomainone{
    margin-bottom: 10px;
  }
  .twomaintwo{
    margin-bottom: 10px;

  }
 

}





/* 3번째 열 project-finder */


.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 섹션 내 요소를 수직으로 가운데 정렬합니다. */
  text-align: center;
  /* 내용을 가운데 정렬합니다. */
  
  /* 화면 높이의 100%만큼의 높이를 가짐 */
  background-color: whitesmoke;
  
  height: 30%;
}


.project-finder {
  display: flex;
  align-items: center;
  /* 섹션 내 요소를 수직으로 가운데 정렬합니다. */
  text-align: center;
  /* 내용을 가운데 정렬합니다. */
  width: 60%;
  background-color: whitesmoke;
  margin: 0;
}

#allcard {
}



.col-sm-6:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* 마우스가 올라갔을 때 그림자 효과 */
}

@media screen and (max-aspect-ratio: 1/1) {

  /* 안드로이드 화면 모드인 경우 */
  .project {
    padding-top: 50px;
    height: max-content;
    padding-bottom: 100px;
  }
}



  /* 파란줄 */
#blueline {
  text-align: center;
  /* 섹션 내 요소를 수직으로 가운데 정렬합니다. */
  height: 60px;
  /* 화면 높이의 100%만큼의 높이를 가짐 */
  background-color: whitesmoke;
  margin: 0;
  margin-bottom: 50px;

}


/* 슬라이드 */

.infinite-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-content {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.left-scroll-item {
  margin-bottom: 100px; /* 왼쪽 컨텐츠 아이템 간격을 100px로 설정 */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px; /* 위 여백을 100px로 설정 */
}

.slidefour{
  margin-bottom: 100px;
}



.left-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.left-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}




.scrolling {
  padding: 0;
  margin: 0;
  height: auto; /* 높이 수정 */
  overflow-y: auto; /* 스크롤이 필요할 때만 나타나도록 조정 */
  padding-bottom: 20px; /* 스크롤 컨테이너 아래쪽에 패딩 추가 */
  
}
.scrolltext{
  margin-left: 500px;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; /* 항목 간 간격 추가 */
  
}

.scrolltext:last-child {
  margin-bottom: 0;
}

.scrolimage{
  margin-right:500px;
}
  


@media screen and (max-aspect-ratio: 1/1) {

  /* 안드로이드 화면 모드인 경우 */
  .scrolltext{
    display: flex; /* Flex container로 설정 */
    flex-direction: column; /* 아이템을 세로 줄로 배치 */
    justify-content: center; /* 세로 방향으로 중앙 정렬 */
    align-items: center;
    height: 400px;
    
    

  }
  .scrolling {
    padding: 0;
    margin: 0;
    
   
    
    
  }
  .scrolltext{
    padding: 0;
    margin: 0;
    height: 50px;
    

  }
  .scrolimage{
    padding: 0;
    margin: 0;
    margin-bottom: 350px;
   

    

  }
}



/* footer */

.footer-container {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.logo-item {
  margin: 0 10px;
  text-align: center;
}

.logo-title {
  margin-top: 5px;
}


.custom-link {
  color: inherit; /* 링크 색상을 부모 요소의 색으로 상속합니다. */
  text-decoration: none; /* 밑줄을 제거합니다. */
}

.logo-item1{
 margin-right: 100px;
}


#appbutton{
  
}
